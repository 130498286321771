/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import nexo from '../../nexoClient';
import tokens from '@nimbus-ds/tokens/dist/js/tokens';
import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import { Box } from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';

import axiosInstance from '../../axios';
import { useWindowWidth } from '../../hooks';
import { CogIcon, QuestionCircleIcon } from '@nimbus-ds/icons';

const ConfigPage: React.FC = () => {
  const { push } = useHistory();
  const windowWidth = useWindowWidth();
  const breakpointMd = tokens.breakpoint.md.value.replace('px', '');

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  const goToLogs = () => push('/logs');
  const goToSetup = () => push('/setup');

  return (
    <Page maxWidth="1300px">
      <Page.Header title="Configuraciones" />
      <Page.Body>
        <Layout columns="1" gap="4">
          <Layout.Section>
            <Box></Box>
          </Layout.Section>
        </Layout>
      </Page.Body>
    </Page>
  );
};

export default ConfigPage;
