import React, { useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import { Box, useToast } from '@nimbus-ds/components';
import { CogIcon, LockOpenIcon, UserIcon } from '@nimbus-ds/icons';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { useHistory } from 'react-router-dom';

const SetupPage: React.FC = () => {
  const { push } = useHistory();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [isVerifing, setIsVerifing] = useState(false);
  const [isVinculate, setIsVinculate] = useState(false);

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  if (isVerifing)
    return (
      <div>
        <label>Verificando vinculación...</label>
      </div>
    );

  return (
    <Page maxWidth="1000px" marginTop="18">
      {!isVerifing && isVinculate ? (
        <>
          <Page.Header
            alignItems="center"
            title="Tu cuenta ya está vinculada y activa"
          />
          <Box display="flex" gap="4" justifyContent="center"></Box>
        </>
      ) : (
        <>
          <Page.Header alignItems="center" title="Sellers" />
          <Page.Body>
            <Layout columns="1">
              <Layout.Section>
                <Box></Box>
              </Layout.Section>
            </Layout>
          </Page.Body>
        </>
      )}
    </Page>
  );
};

export default SetupPage;
