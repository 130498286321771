import React, { useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import { Page, Layout, FormField, InteractiveList } from '@nimbus-ds/patterns';
import {
  Box,
  Button,
  Card,
  Icon,
  Spinner,
  List,
  useToast,
  Input,
  Text,
  Title,
  Textarea,
  Label,
  Tabs,
  Accordion,
  Toggle,
  Tag,
  Select,
  IconButton,
} from '@nimbus-ds/components';
import { CogIcon, LockOpenIcon, UserIcon } from '@nimbus-ds/icons';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { useHistory } from 'react-router-dom';
import { useWindowWidth } from '../../hooks';
import tokens from '@nimbus-ds/tokens/dist/js/tokens';

import axiosInstance from '../../axios';

const PageDashboard: React.FC = () => {
  const { push } = useHistory();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [isVerifing, setIsVerifing] = useState(false);
  const [isVinculate, setIsVinculate] = useState(false);
  const [warehouseVisible, setWarehouseVisible] = useState<string | null>();
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [errorValidateStore, setErrorValidateStore] = useState(false);
  const [error, setError] = useState(false);

  const [flavorName, setFlavorName] = useState<string | null>();
  const [flavorSKU, setFlavorSKU] = useState<string | null>();
  const [flavorOrder, setFlavorOrder] = useState<number | null>();

  const windowWidth = useWindowWidth();
  const breakpointMd = tokens.breakpoint.md.value.replace('px', '');

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  useEffect(() => {
    setWarehouseVisible(null);
    getWarehouses();
  }, []);

  interface Flavor {
    name: string;
    sku: string;
    order: number | null;
    active: boolean;
  }

  interface Warehouse {
    name: string;
    ref: string;
    store_number: number;
    warehouse_code: string;
    flavors: Flavor[];
    active: boolean;
  }

  const getWarehouses = async () => {
    console.log('obteniendo sucursales...');
    try {
      const response = await axiosInstance.get('/warehouses');
      console.log(response);
      if (response.data) {
        console.log(response.data);
        setWarehouses(response.data);
      }
    } catch (e) {
      console.error(e);
    }
    return;
  };

  const handleFlavorName = async (e: any) => {
    console.log(e.target.value);
    setFlavorName(e.target.value);
  };

  const handleFlavorSKU = async (e: any) => {
    console.log(e.target.value);
    setFlavorSKU(e.target.value);
  };

  const handleWarehouseStatus = async (e: any) => {
    //console.log('warehouse', e.target.name, 'ref:', e.target.value);
    //console.log('status:', e.target.checked);
    const warehouse = warehouses.find((w) => w.ref === String(e.target.value));
    if (warehouse) {
      await axiosInstance
        .post('/update_warehouse_status', {
          warehouse_ref: warehouse.ref,
          active: e.target.checked,
        })
        .then((response) => {
          warehouse.active = e.target.checked;

          const w_filter = warehouses.filter(
            (w) => w.ref != String(e.target.value),
          );

          const index = warehouses.indexOf(warehouse);

          const newArrayWarehouses = [
            ...w_filter.slice(0, index),
            warehouse,
            ...w_filter.slice(index),
          ];

          setWarehouses([...newArrayWarehouses]);
          addToast({
            id: 'warehouse-status-success-toast',
            type: 'success',
            text: ' Estado de sucursal actualizado',
            duration: 4000,
          });
        })
        .catch((e) => {
          addToast({
            id: 'error-warehouse-status-toast-1',
            type: 'danger',
            text:
              e.response?.data?.message ??
              'Error al tratar de actualizar el estado',
            duration: 4000,
          });
        });
    } else {
      addToast({
        id: 'error-warehouse-status-toast-2',
        type: 'danger',
        text: 'No se ha podido actualizar. Sucursal no encontrada',
        duration: 4000,
      });
      return;
    }
    return;
  };

  const handleToogleFlavor = async (e: any) => {
    console.log('sku%warehouse', e.target.name);
    console.log('status:', e.target.checked);
    const split_ref = e.target.name.split('%');
    if (split_ref.length > 1) {
      const warehouse = warehouses.find((w) => w.ref === String(split_ref[0]));
      if (warehouse) {
        const flavor = warehouse.flavors.find((f) => f.sku == split_ref[1]);
        if (flavor) {
          flavor.active = e.target.checked;
        }
      }
    }
  };

  const handleChangeOrder = async (e: any) => {
    const order = e.target.value;
    const id = e.target.id;
    //console.log('handle change order with order ' + order + ' and sku ' + id);
    const split_ref = id.split('%?%');
    if (split_ref.length > 1) {
      const warehouse = warehouses.find((w) => w.ref === String(split_ref[0]));
      if (warehouse) {
        const flavor = warehouse.flavors.find((f) => f.sku == split_ref[1]);
        if (flavor) {
          flavor.order = parseInt(order);
        } else {
          console.log('error, flavor not found with sku ', split_ref[1]);
        }
      }
    } else {
      console.log('error');
    }
  };

  const saveConfigFlavors = async (warehouse_ref: string) => {
    console.log('save config flavors ', warehouse_ref);
    const warehouse = warehouses.find((w) => w.ref === String(warehouse_ref));
    if (warehouse) {
      if (warehouse.flavors.length == 0) {
        addToast({
          id: 'flavor-error-order-toast-1',
          type: 'danger',
          text: 'No se pudo guardar. Debe ingresarse por lo menos 1 sabor.',
          duration: 4000,
        });
        return;
      }
      if (warehouse.flavors.length == 1) {
        if (warehouse.flavors[0].order == null) {
          addToast({
            id: 'flavor-error-order-toast-2',
            type: 'danger',
            text: 'No se pudo guardar. Debe asignar orden a todos los elementos',
            duration: 4000,
          });
          return;
        }
      }
      for await (const f1 of warehouse.flavors) {
        for await (const f2 of warehouse.flavors) {
          if (f1.sku != f2.sku) {
            if (f1.order == null) {
              addToast({
                id: 'flavor-error-order-toast-3',
                type: 'danger',
                text: 'No se pudo guardar. Debe asignar orden a todos los elementos',
                duration: 4000,
              });
              return;
            }
            if (f1.order == f2.order) {
              addToast({
                id: 'flavor-error-order-toast-4',
                type: 'danger',
                text: 'No se pudo guardar. Los números del ordenamiento deben ser diferentes',
                duration: 4000,
              });
              return;
            }
          }
        }
      }
      await axiosInstance
        .post('/save_config_flavors', {
          warehouse: warehouse,
        })
        .then((response) => {
          addToast({
            id: 'save-config-success-toast',
            type: 'success',
            text: 'Configuración actualizada',
            duration: 4000,
          });
        })
        .catch((e) => {
          addToast({
            id: 'save-config-error-toast',
            type: 'danger',
            text: e.response?.data?.message ?? 'Error al actualizar datos.',
            duration: 4000,
          });
        });
    } else {
      addToast({
        id: 'warehouse-error-order-toast',
        type: 'danger',
        text: 'No se ha podido guardar. Sucursal no encontrada',
        duration: 4000,
      });
      return;
    }
  };

  const createFlavor = async (warehouse_ref: string) => {
    console.log('create flavor on warehouse: ' + warehouse_ref);
    if (flavorName && flavorSKU) {
      if (flavorSKU.includes(' ')) {
        addToast({
          id: 'create-favor-error-toast-1',
          type: 'danger',
          text: 'El SKU no puede tener espacios',
          duration: 4000,
        });
        return;
      }
      const w = warehouses.find((w) => w.ref == String(warehouse_ref));
      if (w) {
        const index = warehouses.indexOf(w);
        await axiosInstance
          .post('/create_flavor', {
            warehouse_ref: w.ref,
            name: flavorName,
            sku: flavorSKU,
            order: null,
            active: 0,
          })
          .then((response) => {
            if (response.data.status == true) {
              //flavor success
              const flavor: Flavor = {
                name: flavorName,
                sku: flavorSKU,
                order: null,
                active: false,
              };

              const w_filter = warehouses.filter(
                (w) => w.ref != String(warehouse_ref),
              );

              w.flavors.push(flavor);

              const newArrayWarehouses = [
                ...w_filter.slice(0, index),
                w,
                ...w_filter.slice(index),
              ];

              setWarehouses([...newArrayWarehouses]);

              addToast({
                id: 'create-favor-success-toast',
                type: 'success',
                text: response.data.message,
                duration: 4000,
              });
            } else {
              addToast({
                id: 'create-favor-error-toast-2',
                type: 'danger',
                text:
                  response?.data?.message ?? 'Error al dar de alta el gusto',
                duration: 4000,
              });
            }
          })
          .catch((e) => {
            addToast({
              id: 'create-favor-error-toast-3',
              type: 'danger',
              text:
                e.response?.data?.message ?? 'Error al dar de alta el gusto',
              duration: 4000,
            });
          });
      } else {
        addToast({
          id: 'create-favor-error-toast-4',
          type: 'danger',
          text: 'Error: Sucursal no encontrada',
          duration: 4000,
        });
      }
    } else {
      addToast({
        id: 'create-favor-error-toast-3',
        type: 'danger',
        text: 'Debe ingresar todos los datos',
        duration: 4000,
      });
    }
  };

  const deleteFlavor = async (warehouse_ref: string, sku: string) => {
    const w = warehouses.find((w) => w.ref == String(warehouse_ref));
    if (w) {
      await axiosInstance
        .post('/delete_flavor', {
          warehouse_ref: w.ref,
          sku: sku,
        })
        .then((response) => {
          w.flavors = w.flavors.filter((flavor) => flavor.sku !== sku);

          const index = warehouses.indexOf(w);

          const w_filter = warehouses.filter(
            (w) => w.ref != String(warehouse_ref),
          );

          const newArrayWarehouses = [
            ...w_filter.slice(0, index),
            w,
            ...w_filter.slice(index),
          ];

          setWarehouses([...newArrayWarehouses]);

          addToast({
            id: 'delete-flavor-success-toast',
            type: 'success',
            text: response.data.message,
            duration: 4000,
          });
        })
        .catch((e) => {
          addToast({
            id: 'delete-favor-error-toast-3',
            type: 'danger',
            text:
              e.response?.data?.message ??
              'Error al tratar de eliminar el gusto',
            duration: 4000,
          });
        });
    } else {
      addToast({
        id: 'delete-favor-error-toast-4',
        type: 'danger',
        text: 'Error: Sucursal no encontrada',
        duration: 4000,
      });
    }
  };

  const onChangeWarehouseBox = async (warehouse_ref: string) => {
    setWarehouseVisible(warehouse_ref);
  };

  if (isVerifing)
    return (
      <div>
        <label>Verificando vinculación...</label>
      </div>
    );

  if (errorValidateStore)
    return (
      <div>
        <label>Error al verificar estado de la cuenta.</label>
      </div>
    );

  const actions = (
    <Box display="flex" gap="4">
      {warehouses ? (
        warehouses.map((warehouse: Warehouse, index) => (
          <Button
            key={warehouse.ref}
            appearance="primary"
            onClick={() => onChangeWarehouseBox(warehouse.ref)}
          >
            {warehouse.name}
          </Button>
        ))
      ) : (
        <div></div>
      )}
    </Box>
  );

  return (
    <Page maxWidth="1200px" marginTop="18">
      {!isVerifing && !isVinculate && warehouseVisible != null ? (
        <>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Title>
              <strong>Panel de gustos</strong>
            </Title>
          </Box>
          <Page.Header alignItems="center" title="" buttonStack={actions} />
          <Card>
            <Card.Header title="" />
            <Card.Body>
              <Box>
                {warehouses ? (
                  warehouses.map((warehouse: Warehouse, index) => (
                    <Box
                      key={warehouse.ref}
                      display={
                        warehouse.ref == warehouseVisible ? 'flex' : 'none'
                      }
                      flexDirection="column"
                      borderWidth="1"
                      padding="2"
                      width="100%"
                      justifyContent="flex-start"
                    >
                      <Box
                        marginBottom="8"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Box
                          borderColor="neutral-interactive"
                          borderWidth="1"
                          padding="2"
                          width="100%"
                          maxWidth="300px"
                          justifyContent="flex-start"
                        >
                          <Text as="p" fontSize="highlight" textAlign="left">
                            <strong>Agregar nuevo gusto</strong>
                          </Text>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="flex-start"
                            gap="4"
                            marginTop="6"
                          >
                            <Text as="span">
                              <strong> Ingrese nombre de gusto</strong>
                            </Text>
                            <FormField.Input
                              appendPosition="start"
                              placeholder=""
                              onChange={(event) => {
                                handleFlavorName(event);
                              }}
                            />
                            <Text as="span">
                              <strong> Ingrese sku </strong>
                            </Text>
                            <FormField.Input
                              appendPosition="start"
                              placeholder=""
                              onChange={(event) => {
                                handleFlavorSKU(event);
                              }}
                            />
                            <Button
                              onClick={() => createFlavor(warehouse.ref)}
                              appearance="primary"
                              disabled={warehouse.active == false}
                            >
                              Crear sabor
                            </Button>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="flex-start"
                          justifyContent="space-between"
                          gap="4"
                        >
                          <Text as="span" fontSize="highlight">
                            Estado sucursal
                          </Text>
                          <Toggle
                            name={warehouse.name}
                            value={warehouse.ref}
                            key={warehouse.ref}
                            active={warehouse.active}
                            onChange={(event) => {
                              handleWarehouseStatus(event);
                            }}
                          />
                        </Box>
                      </Box>
                      <Box>
                        <Box borderStyle="none" marginBottom="6">
                          <Text as="p" fontSize="highlight" textAlign="left">
                            <strong>Listado de gustos</strong>
                          </Text>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                            gap="4"
                            mt="6"
                            mb="8"
                          >
                            <Text
                              as="span"
                              textAlign="center"
                              fontSize="highlight"
                              fontWeight="medium"
                            >
                              Nombre y SKU
                            </Text>
                            <Text
                              as="span"
                              textAlign="center"
                              fontSize="highlight"
                              fontWeight="medium"
                            >
                              Estado
                            </Text>
                            <Text
                              as="span"
                              textAlign="center"
                              fontSize="highlight"
                              fontWeight="medium"
                            >
                              Orden
                            </Text>
                            <Text
                              as="span"
                              textAlign="center"
                              fontSize="highlight"
                              fontWeight="medium"
                            >
                              Eliminar
                            </Text>
                          </Box>
                          {warehouse.flavors ? (
                            warehouse.flavors.map((flavor: Flavor, index) => (
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                width="100%"
                                gap="6"
                                mt="4"
                                mb="4"
                                key={flavor.sku}
                              >
                                <Box minWidth="150px" maxWidth="150px">
                                  <Text fontSize="highlight" textAlign="left">
                                    <strong>{flavor.name}</strong>
                                    <br />
                                    sku: {flavor.sku}
                                  </Text>
                                </Box>
                                <Toggle
                                  key={warehouse.ref + '%' + flavor.sku}
                                  name={warehouse.ref + '%' + flavor.sku}
                                  value={warehouse.ref}
                                  active={flavor.active}
                                  onChange={(event) => {
                                    handleToogleFlavor(event);
                                  }}
                                />
                                <Select
                                  appearance="neutral"
                                  id={warehouse.ref + '%?%' + flavor.sku}
                                  name="Orden"
                                  onChange={(event) => {
                                    handleChangeOrder(event);
                                  }}
                                >
                                  <Select.Option
                                    disabled
                                    label="Seleccionar"
                                    selected={flavor.order == null}
                                    value="0"
                                  />
                                  {[...Array(101)].map((_x, i) => (
                                    <Select.Option
                                      key={
                                        warehouse.ref +
                                        '-' +
                                        flavor.sku +
                                        '-' +
                                        i
                                      }
                                      label={String(i)}
                                      selected={flavor.order == i}
                                      value={String(i)}
                                    />
                                  ))}
                                </Select>
                                <Box alignSelf="center">
                                  <Text
                                    fontSize="highlight"
                                    textAlign="left"
                                    as="span"
                                    onClick={() =>
                                      deleteFlavor(warehouse.ref, flavor.sku)
                                    }
                                  >
                                    <strong>X Eliminar</strong>
                                  </Text>
                                </Box>
                              </Box>
                            ))
                          ) : (
                            <div></div>
                          )}
                        </Box>
                        <Button
                          onClick={() => saveConfigFlavors(warehouse.ref)}
                          appearance="primary"
                          disabled={
                            warehouse?.flavors
                              ? warehouse?.flavors.length > 0
                                ? false
                                : true
                              : true
                          }
                        >
                          Guardar
                        </Button>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <div></div>
                )}
              </Box>
            </Card.Body>
          </Card>
        </>
      ) : (
        <>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Title>
              <strong>Panel de gustos</strong>
            </Title>
          </Box>
          <Page.Header alignItems="center" title="" buttonStack={actions} />
          <Card>
            <Card.Header title="" />
            <Card.Body>
              <Box display="flex" justifyContent="center">
                <Text as="p" fontSize="highlight">
                  Seleccionar sucursal en la botonera superior
                </Text>
              </Box>
            </Card.Body>
          </Card>
        </>
      )}
    </Page>
  );
};

export default PageDashboard;
